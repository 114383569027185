<div *ngIf="preload"  style="text-align: center;
  width: 100%;
  display: table;
  position: fixed;
  height: 100vh;
  z-index: 9999999;
  background: #e6f7ff96;">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading" class="preload" style="font-size: 40px;"></i> </ng-template>
  <nz-spin nzSimple [nzIndicator]="indicatorTemplate" style="display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100vh;"> </nz-spin>
</div>

<router-outlet></router-outlet>
