import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthenGuard } from "./authen.guard";

const routes: Routes = [
  {
    path: "seller/sign-in",
    loadChildren: () =>
      import("./seller/sign-in/sign-in.module").then(m => m.SignInModule)
  },
  {
    path: "seller",
    data: { type: "seller" },
    canActivate: [AuthenGuard],
    canActivateChild: [AuthenGuard],
    loadChildren: () =>
      import("./seller/seller.module").then(m => m.SellerModule)
  },
  {
    path: "system/sign-in",
    loadChildren: () =>
      import("./system/sign-in/sign-in.module").then(m => m.SignInModule)
  },
  {
    path: "system",
    data: { type: "admin" },
    canActivate: [AuthenGuard],
    canActivateChild: [AuthenGuard],
    loadChildren: () =>
      import("./system/system.module").then(m => m.SystemModule)
  },
  {
    path: "",
    loadChildren: () =>
      import("./market/market.module").then(m => m.MarketModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
