import { Action } from '@ngrx/store';


export enum ActionTypes {
    PRELOAD = '[Action] PRELOAD',
    MARKET_MENU = '[Action] MARKET_MENU',
    MARKET_CART = '[Action] MARKET_CART'
}

export class PRELOAD implements Action{
    readonly type = ActionTypes.PRELOAD;
}
export class MARKET_MENU implements Action{
  readonly type = ActionTypes.MARKET_MENU;
}

export class MARKET_CART implements Action{
  readonly type = ActionTypes.MARKET_CART;
}


export type ActionAction
    =  PRELOAD
    | MARKET_MENU
    | MARKET_CART
