import { Action } from '@ngrx/store';


export enum ProductTypes {
  SET_PRODUCT_LIST = '[Product] SET_PRODUCT_LIST'
}

export class SET_PRODUCT_LIST implements Action{
  readonly type = ProductTypes.SET_PRODUCT_LIST;
  constructor(public playload: any){}
}


export type ProductAction
    =  SET_PRODUCT_LIST
