import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { SellerService } from '../services/seller.service';
import { SET_SELLER, SellerTypes, PRELOAD } from '../actions';

@Injectable()
export class SellerEffects {

  constructor(
    private actions$: Actions,
    private _seller: SellerService,
    private store: Store<{ account: any }>
  ) {}

  
  GET_SELLER_INFO$ = createEffect(() => this.actions$
    .pipe(
      ofType(SellerTypes.GET_SELLER_INFO),
      mergeMap((data:any) => {
        setTimeout(() => {
          if(data.playload){
            this.store.dispatch(new PRELOAD())
          }
        });
        return this._seller.sellerInfo().then((res:any)=> {
          this.store.dispatch(new SET_SELLER(res))
          if(data.playload){
            this.store.dispatch(new PRELOAD())
          }
        })
        .catch(() => {
          this.store.dispatch(new SET_SELLER({}))
          if(data.playload){
            this.store.dispatch(new PRELOAD())
          }
        })
      })
    ), {dispatch: false});


}
