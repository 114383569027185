import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store } from './store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { APIInterceptor } from './http.interceptor';
import { AccountEffects, SellerEffects, CartEffects, CustomerEffects } from './effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SortablejsModule } from 'ngx-sortablejs'
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';

registerLocaleData(en);


// import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

// @Injectable()
// class CustomUrlSerializer implements UrlSerializer {

//   constructor(private _defaultUrlSerializer: DefaultUrlSerializer){}

//   parse(url: string): UrlTree {

//     url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');

//     return this._defaultUrlSerializer.parse(url)
//   }

//   serialize(tree: UrlTree): string {
//     return this._defaultUrlSerializer.serialize(tree).replace(/%28/g, '(').replace(/%29/g, ')');
//   }
// }

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SortablejsModule.forRoot({ animation: 150 }),
    StoreModule.forRoot(Store),
    EffectsModule.forRoot([
      AccountEffects,
      SellerEffects,
      CartEffects,
      CustomerEffects
    ]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, name: 'KET-MARKRT-STORE' }),
    StoreRouterConnectingModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NzSpinModule,
    NzIconModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    { provide: NZ_I18N, useValue: en_US },
    // DefaultUrlSerializer,
    // { provide: UrlSerializer, useClass: CustomUrlSerializer },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
