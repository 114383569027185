import { CartStore, ActionStore, AccountStore, SellerStore, ProductStore, CustomerStore } from "./reducers";
// import { routerReducer } from "@ngrx/router-store";

export let Store: any = {
  // router: routerReducer,
  action: ActionStore,
  cart: CartStore,
  account: AccountStore,
  seller: SellerStore,
  product: ProductStore,
  customer: CustomerStore
};
