import { Action } from '@ngrx/store';


export enum SellerTypes {
  SET_SELLER = '[Seller] SET_SELLER',
  GET_SELLER_INFO = '[Seller][API] GET_SELLER_INFO'
}

export class SET_SELLER implements Action{
  readonly type = SellerTypes.SET_SELLER;
  constructor(public playload: any){}
}


export type SellerAction
    =  SET_SELLER
