import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IKetshopwebPaymentGatewayService } from './ketshopweb-payment-gateway.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private _http: HttpClient) { }


  searchSellerOrder(data){
    return this._http.post('/services/yalamarket-core/order/seller/search', data).toPromise();
  }

  searchAdminOrder(data){
    return this._http.post('/services/yalamarket-core/order/admin/search', data).toPromise();
  }

  updateOrderComplete(data){
    return this._http.post('/services/yalamarket-core/order/updateOrderComplete', data).toPromise();
  }

  cancelOrder(data) {
    return this._http.post('/services/yalamarket-core/order/cancel', data).toPromise();
  }

  calculate(data:any){
    if(data == 'set'){
      try{
        data = JSON.parse(localStorage.getItem('current_cart'))
      }catch(err){}
    }
    try{
      if(data.details.length == 0){
        return new Promise((s,j)=>{
          j(null)
        })
      }
    }catch(err){
      return new Promise((s,j)=>{
        j(null)
      })
    }
    return this._http.post('/services/yalamarket-core/order/calculate', data, {
      headers: {
        'authen-type': 'cus_id'
      }
    }).toPromise()
  }

  sendOTP(data:any){
    return this._http.post('/services/yalamarket-core/sms/sendOTP', data).toPromise();
  }

  verifyOTP(data:any){
    return this._http.post('/services/yalamarket-core/sms/verifyOTP', data).toPromise();
  }

  createOrderCredit(data:any, headers: any){
    console.log('headers', headers)
    return this._http.post('/services/yalamarket-core/order/createOrderCredit', data, {
      headers: {
        'authen-type': 'cus_id',
        ...headers
      }
    }) as Observable<any>;
  }

  createOrderQR(data:any, headers: any){
    return this._http.post('/services/yalamarket-core/order/createOrderQR', data, {
      headers: {
        'authen-type': 'cus_id',
        ...headers
      }
    }) as Observable<any>;
  }

  checkPayStatusOrder(data:any){
    return this._http.post('/services/yalamarket-core/order/checkPayStatusOrder', data).toPromise();
  }

  payStatusOrder(data:any){
    return this._http.post('/services/yalamarket-core/order/checkOrderStatus', data).toPromise();
  }

  getOrderBylink(link:string){
    return this._http.get(`/services/yalamarket-core/order/byLink/${link}`).toPromise()
  }

  getOrderById(id:any){
    return this._http.get(`/services/yalamarket-core/order/byId/${id}`).toPromise()
  }

  getOrderQR(data: any) {
    return this._http.post(`/services/yalamarket-core/order/getOderQR`, data).toPromise()
  }

  confirmDelivery(data:any){
    return this._http.post('/services/yalamarket-core/order/confirmDelivery', data, {
      headers: {
        'authen-type': 'cus_id'
      }
    }).toPromise();
  }

  geocoding(data:any){
    return this._http.post('/services/yalamarket-core/order/geocoding', data).toPromise();
  }


  getCurrentRider(id:number){
    return this._http.post('/services/yalamarket-core/delivery/currentRider', { id: id }).toPromise();
  }

  exportOrders(data: IOrder.ExportOrderModel): Promise<any>{
    return this._http.post('/services/yalamarket-core/order/export', data).toPromise() as any;
  }

  searchOrderHistory(data: {
      page_size: number,
      page: number,
      created_at: string,
      status: string
    },
    customer_id = null
  ) {
    return this._http
      .post("/services/yalamarket-core/order/customer/order-history", data,
      customer_id
          ? {
              params: {
                customer_id,
              },
            }
          : {}
      )
      .toPromise() as Promise<IOrder.OrderHistoryResponse>;
  }

  getQRImage(data: { cus_id: number; ordercode: string }) {
    return this._http.get(
      "/services/yalamarket-core/order/qr-image",
      {
        params: data,
      }
    ) as Observable<{ file_name: string; path: string }>;
  }

  createOrder(body: IOrder.CreateOrderBody, headers: { [key: string]: string } = {}) {
    return this._http.post<{
      link: string;
      cus_id: number;
      ordercode: string;
      status: number;
      totals: number;
      qr?: IKetshopwebPaymentGatewayService.GenerateQRCodeWithoutOrderResponse;
      credit?: IKetshopwebPaymentGatewayService.ChargeCreditCardRedirectWithoutOrderResponse;
    }>("/services/yalamarket-core/order/create", body, {
      headers,
    });
  }

}

export namespace IOrder {

  export interface OriginAttributes {
    project_name: string;
    contact_name: string;
    street: string;
    province: string;
    district: string;
    sub_district: string;
    phone_number: string;
    latitude: number;
    longitude: number;
    landmark: string;
  }

  export interface DestinationAttributes {
    name: string;
    contact_name: string;
    house_number: string;
    project_name: string;
    street: string;
    province: string;
    district: string;
    sub_district: string;
    phone_number: string;
    latitude: number;
    longitude: number;
    landmark: string;
  }

  export interface EstimateData {
    origin_attributes: OriginAttributes;
    destination_attributes: DestinationAttributes;
  }

  export interface Estimateprice {
    fractional: number;
    decimal: number;
    display_value: string;
    full_display: string;
    currency: string;
  }

  export interface Origin {
    id: number;
    type: string;
    contact_name: string;
    phone_number: string;
    masked_address: string;
    latitude: number;
    longitude: number;
    sub_district: string;
  }

  export interface Destination {
    id: number;
    type: string;
    contact_name: string;
    phone_number: string;
    masked_address: string;
    latitude: number;
    longitude: number;
    sub_district: string;
  }

  export interface Route {
    id: number;
    type: string;
    distance?: any;
    duration?: any;
    encoded_polyline?: any;
    bounds?: any;
    origin: Origin;
    destination: Destination;
  }

  export interface Delivery {
    estimateprice: Estimateprice;
    route: Route;
    name: string;
    price: number;
  }

  export interface Paymenttype {
    id: number;
    name: string;
    account: string;
    status: number;
    created_at?: any;
    updated_at: string;
    created_by?: any;
    updated_by?: any;
  }

  export interface Detail {
    id: number;
    note?: any;
    order_id: number;
    product_id: number;
    cus_id: number;
    qty: number;
    price: number;
    old_price: number;
    prop_enable: number;
    feature_img: string;
    title_lang1: string;
    title_lang2: string;
    title_lang3: string;
    title_lang4: string;
    properties_desc?: any;
    property_info?: any;
    properties_desc2?: any;
    property_info2?: any;
    sku: string;
    cate_id: number;
    weight: number;
    options_name: string;
    product_price: number;
    transaction_ref: string;
    updated_at: string;
    created_at: string;
    created_by?: any;
    updated_by?: any;
  }

  export interface Seller {
    id: number;
    cus_id: number;
    tax_no: string;
    branch: number;
    branch_name?: any;
    logo: string;
    shop_name: string;
    tel: string;
    term_link: string;
    type: string;
    custom_type?: any;
    custom_open: number;
    product_limit: number;
    status: number;
    seo_title?: any;
    seo_desc?: any;
    seo_image?: any;
    created_at: string;
    updated_at: string;
    created_by?: any;
    updated_by?: any;
    address: string;
    province: string;
    district: string;
    subdistrict: string;
    zipcode: number;
    lat: string;
    lng: string;
  }

  export interface Order {
    id: number;
    link: string;
    link_status: number;
    balance_transfer_status: number;
    balance_transfer_payment_rate: number;
    balance_transfer_payment_fee: number;
    balance_transfer_marketing_fee: number;
    balance_transfer_vat_rate: number;
    balance_transfer_vat: number;
    balance_transfer_delivery_price: number;
    balance_transfer_ket_fee: number;
    balance_transfer_total: number;
    balance_transfer_date?: any;
    balance_transfer_ref?: any;
    delivery_type: string;
    delivery_provider: string;
    delivery_result?: any;
    created_status: number;
    ordercode: string;
    channel: string;
    status: number;
    complete: number;
    actor?: any;
    user_id?: any;
    tax_no?: any;
    estimate_data: EstimateData;
    shippingorder?: any;
    purchase_id?: any;
    shipconfirm: string;
    shipconfirm_date?: any;
    cus_id: number;
    delivery: Delivery;
    delichargeaddon: number;
    delicharge: number;
    add_delicharge: number;
    amount: number;
    amount_receive?: any;
    bankname?: any;
    banknote?: any;
    vat: number;
    vattype: number;
    vatrate: number;
    discount: number;
    discountaddon: number;
    totals: number;
    order_date: Date;
    updated_at: string;
    created_at: string;
    trackcode?: any;
    name: string;
    address1: string;
    address2?: any;
    district: string;
    subdistrict: string;
    province: string;
    paymenttype: Paymenttype;
    payment: string;
    zipcode: string;
    tel: string;
    weight: number;
    note?: any;
    bill_address1: string;
    bill_address2?: any;
    bill_district: string;
    bill_subdistrict: string;
    bill_province: string;
    bill_zipcode: string;
    bill_name: string;
    bill_lastname: string;
    bill_tel: string;
    shippopbfdata?: any;
    shippopdata?: any;
    shippop?: any;
    freeshipping: number;
    freeshipping_manual?: any;
    qutation_id?: any;
    coupon_code?: any;
    promotion_data?: any;
    created_by: string;
    updated_by: string;
    frm_table?: any;
    frmtb_id?: any;
    customer_note?: any;
    creditpaytype: string;
    transaction_no: string;
    remark?: any;
    customer_id: number;
    details: Detail[];
    seller: Seller;
  }

  export interface OrderHistoryResponse {
    count: number;
    data: Order[];
  }

  export interface ExportOrderModel {
    balance_transfer_ref?: string;
    cus_id?: number;
    shop_name?: string;
    status?: number;
    startdate?: string;
    enddate?: string;
  }

  export interface CreateOrderBody {
    ref?: string
    tel?: string
    coupon_code?: string
    ignore_shipping_address?: boolean
    payment_type: string
    shipping_address: {
        name: string
        tel: string
        address1: string
        province: string
        district: string
        subdistrict: string 
        zipcode: string
        lat: string
        lng: string
    }
    details: {
        id: number
        qty: number
        options: {
            id: number
            option_id?: number
            cus_id: number
            product_id: number
            require: number
            multiple: number
            name: string
            created_at: string
            updated_at: string
        }[]
    }[],
    card?: {
      cvv: string;
      exp_month: string;
      exp_year: string;
      name: string;
      number: string;
    }
  }
}


