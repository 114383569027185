import { CartTypes, CartAction } from '../actions';
import { cloneDeep } from 'lodash'

interface CartProduct{
  name: string,
  product_type: string,
  feature_img: string,
  price: number,
  temp_price: number,
  id: string,
  qty: number,
  property_info: string,
  properties_desc: string,
  property_info2: string,
  properties_sort2: string,
  prop_enable: number,
  note: string,
  options:any[],
  ref: string
}

interface CartShop{
  cus_id: number,
  seller: any,
  details: CartProduct[],
  coupon_code: string
}

interface CartState {
  current: CartShop,
  shops: CartShop[],
  calculate:any,
}

let InitialState: CartState = {
  current: {
    cus_id: null,
    seller: {},
    details: [],
    coupon_code: ''
  },
  shops: [],
  calculate: null,
}

export function CartStore(state = InitialState, action: CartAction): CartState {
    switch (action.type) {
        case CartTypes.SET_CALCULATE:
          return {
            ...state,
            calculate: cloneDeep(action.playload)
          }
        case CartTypes.INIT_CART:
          let initial: CartShop = {
            cus_id: 0,
            seller: {},
            details: [],
            coupon_code: ''
          }
          let current_cart = { ...initial }
          try{
            current_cart = JSON.parse(localStorage.getItem('current_cart'))
          }catch(err){}
          if(!current_cart){
            current_cart = { ...initial }
          }
          let isChange = false;
          for (let key in initial) {
            if (!current_cart.hasOwnProperty(key)) {
              current_cart[key] = initial[key];
              isChange = true;
            }
          }
          if (isChange) {
            localStorage.setItem('current_cart', JSON.stringify(current_cart));
          }
          return {
              ...state,
              current: cloneDeep(current_cart),
              shops: cloneDeep([current_cart]),
          }
        case CartTypes.SET_CURRENT_CART:
          var _state: CartState = cloneDeep(state)
          var cus_id:any = action.playload.cus_id
          // console.log(_state)
          var shop: CartShop = _state.shops.find(x => x.cus_id == cus_id)
          if(!shop){
            shop = {
              cus_id : cus_id,
              seller: action.playload.seller,
              details : [],
              coupon_code: ''
            }
            _state.shops.push(shop)
          }else{
            shop.seller = action.playload.seller
          }
          localStorage.setItem('current_cart', JSON.stringify(shop))
          return {
            ...state,
            current: cloneDeep(shop),
            shops: cloneDeep(_state.shops),
          }
        case CartTypes.SET_NOTE:
          var _state: CartState = cloneDeep(state)
          // console.log('1',action.playload)
          var cus_id:any = action.playload.cus_id
          var shop: CartShop = _state.shops.find(x => x.cus_id == cus_id)
          if(!shop){
            return {
              ...state
            }
          }
          // console.log('2',action.playload)
          var _product = shop.details.find(x => x.ref == action.playload.ref)
          if(!_product){
            return {
              ...state
            }
          }
          _product.note = action.playload.note
          localStorage.setItem('current_cart', JSON.stringify(shop))
          // console.log(_product)
          return {
            ...state,
            current: cloneDeep(shop),
            shops: cloneDeep(_state.shops),
          }

        case CartTypes.ADD_TO_CART:
          var _state: CartState = cloneDeep(state)
          var cus_id:any = action.playload.cus_id
          var shop: CartShop = _state.shops.find(x => x.cus_id == cus_id)
          if(!shop){
            shop = {
              cus_id : cus_id,
              seller:_state.current.seller,
              details : [],
              coupon_code: ''
            }
          }
          var _product = shop.details.find(x => x.ref == action.playload.product.ref)
          if(!_product){
            // console.log(action)
            _product = {
              name: action.playload.product.name,
              product_type: action.playload.product.product_type,
              feature_img: action.playload.product.feature_img,
              id: action.playload.product.id,
              qty: action.playload.product.qty,
              price: action.playload.product.price,
              temp_price: action.playload.product.temp_price,
              property_info:  action.playload.product.property_info,
              properties_desc:  action.playload.product.properties_desc,
              property_info2:  action.playload.product.property_info2,
              properties_sort2:  action.playload.product.properties_sort2,
              prop_enable:  action.playload.product.prop_enable,
              note: action.playload.product.note,
              options: action.playload.product.options,
              ref: action.playload.product.ref
            }
            shop.details.push(_product)
          }else{

            _product.name = action.playload.product.name
            _product.product_type = action.playload.product.product_type
            _product.feature_img = action.playload.product.feature_img
            _product.price = action.playload.product.price
            _product.temp_price = action.playload.product.temp_price
            _product.property_info =  action.playload.product.property_info,
            _product.properties_desc =  action.playload.product.properties_desc,
            _product.property_info2 =  action.playload.product.property_info2,
            _product.properties_sort2 =  action.playload.product.properties_sort2,
            _product.prop_enable =  action.playload.product.prop_enable
            _product.note = action.playload.product.note
            _product.options = action.playload.product.options
            _product.ref = action.playload.product.ref

            if(action.playload.hasOwnProperty('operator')){
              switch (action.playload.operator) {
                case 'delete':
                  shop.details = shop.details.filter(x => x.ref !== _product.ref)
                  break;
                case 'add':
                  _product.qty+=action.playload.product.qty
                  break;
                case '=':
                  _product.qty=action.playload.product.qty
                  break;
                case '+':
                  _product.qty++
                  break;
                case '-':
                  _product.qty--
                  if(_product.qty <= 0){
                    _product.qty = 1
                  }
                  break;
                default:
                  _product.qty=action.playload.product.qty
                  break;
              }
            }else{
              _product.qty=action.playload.product.qty
            }
          }
          localStorage.setItem('current_cart', JSON.stringify(shop))
          return {
            ...state,
            current: cloneDeep(shop),
            shops: cloneDeep(_state.shops),
          }
        case CartTypes.SET_COUPON:
          var _state: CartState = cloneDeep(state)
          _state.current.coupon_code = ''
          if (action.playload) {
            _state.current.coupon_code = action.playload;
          }
          localStorage.setItem('current_cart', JSON.stringify(_state.current))
          return {
            ...state,
            current: cloneDeep(_state.current),
            shops: cloneDeep(_state.shops),
          }
        default:
            return state
    }
}


