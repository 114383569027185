import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { AuthenService } from "./services/authen.service";
import { switchMap, filter, take } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(public auth: AuthenService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var header: any = {};

    try {
      var _0x38df = [
          'X19yZWZfXw==',
          'getItem'
      ];
      (function (_0x26c545, _0x38df73) {
          var _0x24475e = function (_0x110730) {
              while (--_0x110730) {
                  _0x26c545['push'](_0x26c545['shift']());
              }
          };
          _0x24475e(++_0x38df73);
      }(_0x38df, 0x106));
      var _0x2447 = function (_0x26c545, _0x38df73?) {
          _0x26c545 = _0x26c545 - 0x0;
          var _0x24475e = _0x38df[_0x26c545];
          return _0x24475e;
      };
      var _0x3096 = [
          _0x2447('0x1'),
          atob(_0x2447('0x0')),
          atob('eC1kZXZpY2UtcmVm')
      ];
      (function (_0x5d0010, _0x3096e2) {
          var _0x38803b = function (_0x3df62a) {
              while (--_0x3df62a) {
                  _0x5d0010['push'](_0x5d0010['shift']());
              }
          };
          _0x38803b(++_0x3096e2);
      }(_0x3096, 0x1eb));
      var _0x3880 = function (_0x5d0010, _0x3096e2?) {
          _0x5d0010 = _0x5d0010 - 0x0;
          var _0x38803b = _0x3096[_0x5d0010];
          return _0x38803b;
      };
      header[_0x3880('0x0')] = Function[_0x3880('0x2')]();
      header['x-device-uuid'] = localStorage[_0x3880('0x1')]('device_uuid');
    } catch (err) { }

    let baseURL = environment.api_path;

    if (/^\/ketcms\/services/.test(request.url)) {
      baseURL = environment.ket_services_path;
      const url = request.url.replace(/^\/ketcms\/services/, '');
      header['cms-domain'] = environment.ketcms_domain;

      return next.handle(request.clone({
        url: `${baseURL}${url}`,
        setHeaders: header
      }))
    }


    var isToken = "";
    try {
      isToken = this.auth.getToken();
      if (isToken && baseURL) {
        header["Authorization"] = `Bearer ${isToken}`;
      }
    } catch (err) {}
    // console.log('request.headers', request.headers.get('cus-id'))
    try {
      var cus_id = localStorage.getItem('cart_cus_id')
      if (cus_id) {
        header["cus-id"] = request.headers.get('cus-id') || cus_id;
      }
    } catch (err) {}

    console.log('request.headers.key()',  request.headers.keys())

    console.log('header', header)
    
    var check:string = request.url.slice(0,8);
    if(check == "/assets/") {
      var assets = request.clone({
        url: `${window.location.origin}${request.url}`,
        setHeaders: header
      });
      return next.handle(assets);
    }
    
    const apiReq = request.clone({
      url: `${baseURL}${request.url}`,
      setHeaders: header
    });

    return next.handle(apiReq).pipe(
      catchError(error => {
        if (apiReq.url.includes("refresh_token")) {
          this.auth.logout(false);
          return throwError(error);
        }
        if (error.status !== 401) {
          return throwError(error);
        }
        if (!this.auth.getRefreshToken()) {
          this.auth.logout(false);
          return throwError(error);
        }
        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(apiReq)))
          );
        } else {
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);
          return this.auth.refreshToken().pipe(
            switchMap((token: any) => {
              this.auth.setToken(token)
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(token);
              return next.handle(this.addAuthenticationToken(apiReq));
            }),
            catchError((err: any) => {
              this.refreshTokenInProgress = false;
              this.auth.logout(false);
              return throwError(error);
            })
          );
        }
      })
    );
  }

  addAuthenticationToken(request) {
    const accessToken = this.auth.getToken();
    if (!accessToken) {
      return request;
    }
    let setHeeader:any = {}
    if(this.auth.getToken()){
      setHeeader = {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    }
    try {
      var cus_id = localStorage.getItem('cart_cus_id')
      if (cus_id) {
        setHeeader["cus-id"] = request.headers.get('cus-id') || cus_id;
      }
    } catch (err) {}
    return request.clone({
      setHeaders: setHeeader
    });
  }
}
