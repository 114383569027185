import { ICustomer } from '@market/services/customer.service';
import { CustomerTypes, CustomerAction } from '../actions';


export interface customerState {
    profile: ICustomer.Profile
}


let InitialState: customerState = {
	profile: null
}

export function CustomerStore(state = InitialState, action: CustomerAction): customerState {
    switch (action.type) {
        case CustomerTypes.SET_CUSTOMER:
            // console.log('action',action)
            return {
                ...state,
                profile: action.playload.hasOwnProperty('profile')? action.playload.profile : null,
            }
        default:
            return state
    }
}