import { Action } from '@ngrx/store';


export enum CartTypes {
    INIT_CART = '[Cart] INIT_CART',
    SET_CURRENT_CART = '[Cart] SET_CURRENT_CART',
    ADD_TO_CART = '[Cart] ADD_TO_CART',
    CART_CALCULATE = '[Cart] CART_CALCULATE',
    SET_CALCULATE = '[Cart] SET_CALCULATE',
    SET_NOTE = '[Cart] SET_NOTE',
    SET_COUPON = '[Cart] SET_COUPON'
}


export class INIT_CART implements Action{
    readonly type = CartTypes.INIT_CART;
}

export class ADD_TO_CART implements Action{
  readonly type = CartTypes.ADD_TO_CART;
  constructor(public playload: any){}
}

export class SET_CURRENT_CART implements Action{
  readonly type = CartTypes.SET_CURRENT_CART;
  constructor(public playload: any){}
}

export class SET_CALCULATE implements Action{
  readonly type = CartTypes.SET_CALCULATE;
  constructor(public playload: any){}
}

export interface INote{
  cus_id: number | string
  ref: string
  note: string
}

export class SET_NOTE implements Action{
  readonly type = CartTypes.SET_NOTE;
  constructor(public playload: INote){}
}

export class SET_COUPON implements Action{
  readonly type = CartTypes.SET_COUPON;
  constructor(public playload: string){}
}

export type CartAction
    =  INIT_CART
    | ADD_TO_CART
    | SET_CURRENT_CART
    | SET_CALCULATE
    | SET_NOTE
    | SET_COUPON;




