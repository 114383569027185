import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenService } from "./services/authen.service";

@Injectable({
  providedIn: "root"
})
export class AuthenGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthenService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('canActivate', next.data)
    const isTokenValid = this.authService.isTokenExpired(this.authService.getToken(), 12, next.data.type) == false;
    const haveRefreshToken = Boolean(this.authService.getRefreshToken());
    if (isTokenValid && haveRefreshToken) {
      return true;
    }
    try{
      localStorage.setItem('_lastUrlAuthen', location.href)
    }catch(err){}
    try{
      if(next.data.type == 'seller'){
        this.router.navigate(['/seller/sign-in'])
        localStorage.removeItem(this.authService.TOKEN_NAME)
        return false;
      }
    }catch(err){}
    try{
      if(next.data.type == 'admin'){
        this.router.navigate(['/system/sign-in'])
        localStorage.removeItem(this.authService.TOKEN_NAME)
        return false;
      }
    }catch(err){}
    try{
      if(next.data.type == 'customer'){
        this.router.navigate(['/sign-in'])
        localStorage.removeItem(this.authService.TOKEN_NAME)
        return false;
      }
    }catch(err){}
    this.router.navigate(['/sign-in'])
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // console.log('canActivateChild',next.data)
    // console.log(this.authService.isTokenExpired())
    const isTokenValid = this.authService.isTokenExpired(this.authService.getToken(), 12, next.data.type) == false;
    const haveRefreshToken = Boolean(this.authService.getRefreshToken());
    if (isTokenValid && haveRefreshToken) {
      return true;
    }
    try{
      localStorage.setItem('_lastUrlAuthen', location.href)
    }catch(err){}
    try{
      if(next.data.type == 'seller'){
        this.router.navigate(['/seller/sign-in'])
      }
    }catch(err){}
    try{
      if(next.data.type == 'admin'){
        this.router.navigate(['/system/sign-in'])
      }
    }catch(err){}
    try{
      if(next.data.type == 'customer'){
        this.router.navigate(['/sign-in'])
        localStorage.removeItem(this.authService.TOKEN_NAME)
        return false;
      }
    }catch(err){}
    this.router.navigate(['/sign-in'])
    return false;
  }


}
