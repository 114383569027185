import { ProductTypes, ProductAction } from '../actions';


interface productState {
    product_list: any
}


let InitialState: productState = {
  product_list: {}
}

export function ProductStore(state = InitialState, action: ProductAction): productState {
    switch (action.type) {
        case ProductTypes.SET_PRODUCT_LIST:
            return {
                ...state,
                product_list: action.playload
            }
        default:
            return state
    }
}

