import { Action } from '@ngrx/store';


export enum CustomerTypes {
  SET_CUSTOMER = '[Customer] SET_CUSTOMER',
  GET_CUSTOMER_INFO = '[Customer][API] GET_CUSTOMER_INFO'
}

export class SET_CUSTOMER implements Action{
  readonly type = CustomerTypes.SET_CUSTOMER;
  constructor(public playload: any){}
}


export type CustomerAction
    =  SET_CUSTOMER
