import { SellerTypes, SellerAction } from '../actions';


export interface sellerState {
    info: any
    open_time:any[]
    shopaddress:any
    delivery_details: any[]
    bank_account:any,
    branch:any[]
}


let InitialState: sellerState = {
  info: null,
  open_time: [],
  shopaddress: {},
  delivery_details: [],
  bank_account: {},
  branch: []
}

export function SellerStore(state = InitialState, action: SellerAction): sellerState {
    switch (action.type) {
        case SellerTypes.SET_SELLER:
            // console.log('action',action)
            return {
                ...state,
                info: action.playload.hasOwnProperty('data')? action.playload.data : null,
                open_time: action.playload.hasOwnProperty('open_time')? action.playload.open_time : [],
                shopaddress: action.playload.hasOwnProperty('shopaddress')? action.playload.shopaddress : {},
                delivery_details: action.playload.hasOwnProperty('delivery_details')? action.playload.delivery_details : [],
                bank_account: action.playload.hasOwnProperty('bank_account')? action.playload.bank_account : {},
                branch: action.playload.hasOwnProperty('branch')? action.playload.branch : []
            }
        default:
            return state
    }
}

