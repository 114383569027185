import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CustomerService } from '../services/customer.service';
import { SET_CUSTOMER, CustomerTypes, PRELOAD } from '../actions';
import { AuthenService } from '@market/services/authen.service';

@Injectable()
export class CustomerEffects {

  constructor(
    private actions$: Actions,
    private _customer: CustomerService,
    private store: Store<{ account: any }>,
    private authen: AuthenService
  ) {}

  
  GET_CUSTOMER_INFO$ = createEffect(() => this.actions$
    .pipe(
      ofType(CustomerTypes.GET_CUSTOMER_INFO),
      mergeMap((data:any) => {
        setTimeout(() => {
          if(data.playload){
            this.store.dispatch(new PRELOAD())
          }
        });
        if(this.authen.getToken()){
          return this._customer.getProfile().then((res:any)=> {
            this.store.dispatch(new SET_CUSTOMER(res))
            if(data.playload){
              this.store.dispatch(new PRELOAD())
            }
          })
          .catch(() => {
            this.store.dispatch(new SET_CUSTOMER({}))
            if(data.playload){
              this.store.dispatch(new PRELOAD())
            }
          })
        }else{
          this.store.dispatch(new SET_CUSTOMER({}))
          if(data.playload){
            this.store.dispatch(new PRELOAD())
          }
          return Promise.resolve()
        }
      })
    ), {dispatch: false});


}
