import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jwt_decode from "jwt-decode";
import * as moment from 'moment'
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SET_PROFILE, SET_SELLER } from '../actions';
import { UtilService } from './util.service';
import { environment } from 'src/environments/environment';

interface Token {
  access_token: string;
  refresh_token: string;
} 

@Injectable({
  providedIn: 'root'
})
export class AuthenService {

  TOKEN_NAME: string = "ses";

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private store: Store<any>,
    private _util:UtilService
  ) {

  }

  sendOTP(data:any){
    return this._http.post('/services/yalamarket-core/sms/sendOTP', data).toPromise();
  }

  verifyOTP(data:any){
    return this._http.post('/services/yalamarket-core/sms/verifyOTP', data).toPromise();
  }


  getDecode(){
    const decoded = jwt_decode<any>(this.getToken());
    return decoded
  }

  getToken(){
    try{
      return JSON.parse(this._util.getTokenLocalStore(environment.token_local_store,localStorage.getItem(this.TOKEN_NAME))).access_token;
    }catch(err){
      return false
    }
  }

  getRefreshToken(){
    try{
      return JSON.parse(this._util.getTokenLocalStore(environment.token_local_store,localStorage.getItem(this.TOKEN_NAME))).refresh_token;
    }catch(err){
      return false
    }
  }

  setToken(obj:any){
    localStorage.setItem(this.TOKEN_NAME, this._util.cipher(environment.token_local_store,JSON.stringify(obj)))
  }

  getTokenExpirationDate(token: string, hours:number = 0){
    const decoded = jwt_decode<any>(token);

    if (decoded.exp === undefined) return null;

    // const date = new Date(0);
    // date.setUTCSeconds(decoded.exp);
    return moment.unix(decoded.exp).add(hours, 'hours').format('YYYY-MM-DD HH:mm:ss');
  }

  isTokenExpired(token?: string, hours:number = 0, type?:string): boolean {
    try {
      if (!token) token = this.getToken();
      if (!token) return true;
      const date = this.getTokenExpirationDate(token, hours);
      const decoded = jwt_decode<any>(token);
      console.log(date, decoded)
      console.log(moment().format('YYYY-MM-DD HH:mm:ss'))
      if(type){
        if(decoded.type !== type){
          return true;
        }
      }
      if (date === undefined) return true;
      // return date.valueOf() > new Date().valueOf();
      return moment().isSameOrAfter(moment(date))
    } catch (err) {
      localStorage.removeItem(this.TOKEN_NAME);
      return true;
    }
  }


  sellerLogin(data:any){
    return this._http.post('/services/yalamarket-core/authen/seller', data).toPromise()
  }

  sellerRegister(data:any){
    return this._http.post('/services/yalamarket-core/authen/seller/register', data).toPromise()
  }

  sellerCheck(data:any){
    return this._http.post('/services/yalamarket-core/authen/seller/check', data).toPromise()
  }
  sellerForgotPassword(data:any){
    return this._http.post('/services/yalamarket-core/authen/seller/forgot-password', data).toPromise()
  }
  staffLogin(data:any){
    return this._http.post('/services/yalamarket-core/authen/staff', data).toPromise()
  }

  logout(tokenRevoke = true){
    console.log('logout', Date.now())
    // console.log(this._activateRoute.snapshot.data)
    if(localStorage.getItem('account_type') == "seller"){
      this._router.navigate(['/seller/sign-in'])
      this.store.dispatch(new SET_PROFILE({}))
      this.store.dispatch(new SET_SELLER({}))
    }
    if(localStorage.getItem('account_type') == "admin"){
      this._router.navigate(['/system/sign-in'])
    }
    localStorage.removeItem('account_type')
  }

  tokenInfo(){
    // getToken
    if (this.getToken()) {
      // console
      return this._http.get('/services/yalamarket-core/authen/tokeninfo').toPromise()
    }else{
      return new Promise((resolve, reject) => reject('token is null'))
    }
  }

  refreshToken(){
    return this._http.post('/services/yalamarket-core/authen/refresh_token', {
      grant_type: 'refresh_token',
      refresh_token: this.getRefreshToken()
    })
  }

  getDomain(email: string){
    return this._http.post('/services/yalamarket-core/authen/getDomain',{
      email: email
    }).toPromise()
  }

  forgotPassword(data:any){
    return this._http.post('/services/yalamarket-core/authen/sendMailForgotPassword', data, {
      headers: {
        'ket-domain': data.domain_name,
        'authen-type': 'domain'
      }
    }).toPromise()
  }

  resetPassword(data:any){
    return this._http.post('/services/yalamarket-core/authen/resetPassword', data, {
      headers: {
        'ket-domain': data.domain_name,
        'authen-type': 'domain'
      }
    }).toPromise()
  }

  customerCheck(data: { 
    username: string; 
    account_type: 'email' | 'tel' 
  }) {
    return this._http.post('/services/yalamarket-core/authen/customer/check', data).toPromise()
  }
  
  customerTelAuthen(data: {
    tel: string;
    otp: string;
    ref: string;
    type: 'login' | 'register';
  }): Promise<Token> {
    return this._http.post('/services/yalamarket-core/authen/customer-tel', data).toPromise() as any;
  }

  customerEmailAuthen(data: {
    username: string;
    password: string;
    type: 'login' | 'register';
  }) {
    return this._http.post('/services/yalamarket-core/authen/customer-email', data).toPromise() as any;
  }

  customerEmailActivate(token: string) {
    return this._http.get('/services/yalamarket-core/authen/customer-email/activate/' + token).toPromise() as any;
  }

  customerEmailForgotPasswordRequest(data: { 
    username: string 
  }) {
    return this._http.post('/services/yalamarket-core/authen/customer-email/forgot-password', data).toPromise() as any;
  }

  customerEmailForgotPasswordUpdate(token: string, data: { 
    password: string 
  }) {
    return this._http.put('/services/yalamarket-core/authen/customer-email/forgot-password/' + token, data).toPromise() as any;
  }

  lineAuthen(data: { id_token: string; access_token: string }): Promise<Token> {
    return this._http.post('/services/yalamarket-core/authen/customer/line', data).toPromise() as any;
  }


  fbAuthen(data: { access_token: string }): Promise<Token> {
    return this._http.post('/services/yalamarket-core/authen/customer/facebook', data).toPromise() as any;
  }

}
