import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CartTypes, SET_CALCULATE } from '../actions';
import { OrderService } from '../services/order.service';
declare var NProgress:any
@Injectable()
export class CartEffects {

  constructor(
    private actions$: Actions,
    private _order: OrderService,
    private store: Store<{ account: any }>
  ) {}

  
  CART_CALCULATE$ = createEffect(() => this.actions$
    .pipe(
      ofType(CartTypes.CART_CALCULATE),
      mergeMap(() => {
        try{
          NProgress.start();
        }catch(err){}
        return this._order.calculate('set').then((res:any)=> {
          this.store.dispatch(new SET_CALCULATE({err: null,...res}))
          try{
            NProgress.done();
          }catch(err){}
        })
        .catch((err:any) => {
          // console.log(err)
          this.store.dispatch(new SET_CALCULATE({err: err}))
          try{
            NProgress.done();
          }catch(err){}
        })
      })
    ), {dispatch: false});


}
