import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SellerService {

  constructor(
    private _http: HttpClient
  ) { }

  searchShopAddBranch(data){
    return this._http.post('/services/yalamarket-core/seller/searchShopAddBranch', data).toPromise()
  }

  exportShop(model: any = {}){
    return this._http.post('/services/yalamarket-core/seller/export', model)
  }

  sellerInfoByCusIdPublic(cus_id:string){
    return this._http.get('/services/yalamarket-core/seller/sellerinfo',{
      headers: {
        'authen-type': 'cus_id',
        'cus-id': cus_id
      }
    }).toPromise()
  }

  updateMetaTages(data:any){
    return this._http.post('/services/yalamarket-core/seller/meta-tags', data).toPromise()
  }

  sellerInfo(){
    return this._http.get('/services/yalamarket-core/seller/sellerinfo').toPromise()
  }
  sellerInfoByCusId(data:any){
    return this._http.post('/services/yalamarket-core/seller/sellerinfo', data).toPromise()
  }
  registerShop(data:any){
    return this._http.post('/services/yalamarket-core/seller/register', data).toPromise()
  }

  sellerUpdateInfo(data:any){
    return this._http.post('/services/yalamarket-core/seller/update', data).toPromise()
  }

  deleteBranch(data){
    return this._http.post('/services/yalamarket-core/seller/deleteBranch', data).toPromise()
  }
  updateBranch(data){
    return this._http.post('/services/yalamarket-core/seller/updateBranch', data).toPromise()
  }

  sellerUpdateInfoAmin(data:any){
    return this._http.post('/services/yalamarket-core/seller/update', data).toPromise()
  }

  sellerUpdateOpenTimeAdmin(data:any){
    return this._http.post('/services/yalamarket-core/seller/updateOpenTime', data).toPromise()
  }


  sellerUpdateOpenTime(data:any){
    return this._http.post('/services/yalamarket-core/seller/updateOpenTime', data).toPromise()
  }

  updateBankAccount(data:any){
    return this._http.post('/services/yalamarket-core/seller/updateBankAccount', data).toPromise()
  }

  updateBankAccountAdmin(data:any){
    return this._http.post('/services/yalamarket-core/seller/updateBankAccount', data, {
      headers: {
        'cus-id': data.cus_id,
        'authen-type': 'cus_id'
      }
    }).toPromise()
  }

  uploadImage(data:any){
    return this._http.post('/services/yalamarket-core/seller/uploadImage', data).toPromise()
  }

  search(data: ISeller.SearchData): Promise<ISeller.SearchResponse>{
    return this._http.post('/services/yalamarket-core/seller/search', data).toPromise() as any;
  }

  overview(data:any){
    return this._http.post('/services/yalamarket-core/seller/overview',data).toPromise()
  }

  getBestSeller(data: {
    limit: number
}): Promise<ISeller.GetBestSaleResponse> {
    return this._http.post('/services/yalamarket-core/seller/getBestSeller', data).toPromise() as any;
  }

  searchSellerOrderSummary(data: ISeller.SearchOrderSummaryData): Promise<ISeller.SearchOrderSummaryResponse> {
    return this._http.post('/services/yalamarket-core/seller/searchSellerOrderSummary', data).toPromise() as any;
  }


}

export namespace ISeller {
  export interface Entity {
    id: number;
    logo: string;
    cus_id: number;
    delivery_type: string;
    shop_name: string;
    type: string;
    open_status: number;
    today_open_time: string;
    today_close_time: string;
    open_time: OpenTime[];
    mins_before_open: number;
  }

  export interface SearchData {
    shop_name?: string;
    status?: number;
    page: number;
    perpage: number;
    delivery_normal?: boolean;
    delivery_now?: boolean;
    delivery_type?: string;
    cus_ids?: number[];
  }

  export interface SearchResponse {
    count: number;
    data: Entity[];
  }

  export interface OpenTime {
    day_id: number;
    cus_id: number;
    status: number;
    open_time: string;
    close_time: string;
    lang1: string;
  }

  export interface HighLightData {
    title: string;
    type: number;
    cus_ids: number[];
    perpage: number;
    style: {
      border_size: number;
      border_color: string;
      image_size: string;
      bgcolor: string;
      border: boolean;
      head_position: string;
      head_font: string;
      head_font_color: string;
      head_font_size: number;
      seller_img_crop: boolean;
      show_item_dt: number;
      show_item_mb: number;
      slide_status: boolean;
      autoplay: boolean;
      autoplayTimeout: number;
      nav: number;
    };
  }

  export interface HighLightInit {
    uid: string;
  }

  export interface HighLight {
    data: HighLightData;
    init: {
      uid: string;
    };
  }

  export interface GetBestSaleResponse {
    data: {
      id: number;
      cus_id: number;
      logo: string;
      shop_name: string;
      status: number;
      day_id: number;
      open_status: number;
      today_open_time: string;
      today_close_time: string;
      sellCount: number;
      sellSum: number;
      open_time: OpenTime[];
      mins_before_open: number;
    }[];
    total_open: number;
    total_open_soon: number;
    total_other: number;
  }

  export interface SearchOrderSummaryData {
    balance_transfer_ref: string;
    startdate: string;
    enddate: string;
    cus_id?: number;
    shop_name?: string;
    page: number;
    perpage: number;
    balance_transfer_summary?: boolean;
  }

  export interface OrderSummary {
    cus_id: number;
    shop_name: string;
    total_payment_fee: number;
    total_market_fee: number;
    total_delivery_cost: number;
    total_vat: number;
    totals: number;
    total_ket_fee: number;
    transfer_total: number;
    transfer_fee: number;
    created_at?: string;
    updated_at?: string;
    created_by?: string;
    updated_by?: string;
  }

  export interface BalanceTransferSummary {
    sum_total_payment_fee: number;
    sum_total_market_fee: number;
    sum_total_delivery_cost: number;
    sum_total_vat: number;
    sum_totals: number;
    sum_total_ket_fee: number;
    sum_transfer_total: number;
    sum_transfer_fee: number;
    transfer_fee_per_order?: number;
  }

  export interface SearchOrderSummaryResponse {
    count: number;
    data: OrderSummary[];
    balance_transfer_summary?: BalanceTransferSummary;
  }
} 
