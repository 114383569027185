import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthenService } from '../services/authen.service';
import { SET_PROFILE, AccountTypes } from '../actions';

@Injectable()
export class AccountEffects {

  constructor(
    private actions$: Actions,
    private _authen: AuthenService,
    private store: Store<{ account: any }>
  ) {}

  
  GET_PROFILE$ = createEffect(() => this.actions$
    .pipe(
      ofType(AccountTypes.GET_PROFILE),
      mergeMap(() => this._authen.tokenInfo().then(res=> {
        console.log(res)
        this.store.dispatch(new SET_PROFILE(res))
      })
      .catch(() => {this.store.dispatch(new SET_PROFILE({}))})
      )
    ), {dispatch: false});


}
