import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  constructor(private _http: HttpClient) {}
  getProfile(customer_id = null) {
    return this._http
      .get(
        "/services/customer/profile",
        customer_id
          ? {
              params: {
                customer_id,
              },
            }
          : {}
      )
      .toPromise() as Promise<ICustomer.ProfileResponse>;
  }

  updateProfile(data: {
      name: string;
      profile_img: string;
    }, 
    customer_id = null
  ) {
    return this._http
      .put(
        "/services/customer/profile",
        data,
        customer_id
          ? {
              params: {
                customer_id,
              },
            }
          : {}
      )
      .toPromise() as Promise<ICustomer.ProfileResponse>;
  }

  createEditAddress(data: {
      name: string;
      address: string;
      subdistrict: string;
      district: string;
      province: string;
      zipcode: string;
    },
    customer_id = null
  ) {
    return this._http
      .post(
        "/services/customer/address",
        data,
        customer_id
          ? {
              params: {
                customer_id,
              },
            }
          : {}
      )
      .toPromise() as Promise<ICustomer.CustomerAddress>;
  }

  deleteAddress(address_id: any, customer_id = null) {
    return this._http
      .delete(
        "/services/customer/address/" + address_id,
        customer_id
          ? {
              params: {
                customer_id,
              },
            }
          : {}
      )
      .toPromise() as Promise<{ status: boolean; message: string }>;
  }

  updateTel(data: {
    tel: string;
    otp: string;
    ref: string;
  }) {
    return this._http
      .put("/services/customer/tel", data)
      .toPromise() as Promise<ICustomer.ProfileResponse>;
  }

  search(data: {
    page_size: number,
    page: number,
    start_date: string,
    end_date: string;
    search: string
  }) {
    return this._http
      .post("/services/customer/search", data)
      .toPromise() as Promise<ICustomer.SearchResponse>;
  }

  count() {
    return this._http
      .get("/services/customer/count")
      .toPromise() as Promise<ICustomer.CountResponse>;
  }

  export(data: {
    start_date: string,
    end_date: string;
  }) {
    return this._http
      .post("/services/customer/export", data);
  }

  generateGuestToken(data: {
    tel: string;
    ref: string;
  }) {
    return this._http
      .post("/services/customer/guest/token", data) as Observable<{access_token: string}>;
  }
}

export namespace ICustomer {

  export interface CustomerAddress {
    id: number;
    customer_id: number;
    short_desc: string;
    name: string;
    address: string;
    subdistrict: string;
    district: string;
    province: string;
    zipcode: string;
    created_at: string;
    updated_at: string;
    lat: string;
    lng: string;
  }

  export interface Profile {
    name: string;
    email: string;
    tel: string;
    profile_img: string;
    customer_address?: CustomerAddress[];
  }

  export interface ProfileResponse {
    profile: Profile;
  }

  export interface SearchResponse {
    count: number;
    data: {
      id: number;
      role: string;
      created_at: string;
      name: string;
      tel: string;
      email: string;
      order_count: number;
      order_total_value: number;
    }[];
  }

  export interface CountResponse {
    count: number;
    count_customer: number;
    count_guest: number;
}
}
