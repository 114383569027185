import { version } from './version';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  host: 'https://dev.yalamarketplace.com',
  production: false,
  hmr: false,
  api_path: 'https://devapi.yalamarketplace.com',
  token_local_store: '71328377-6206-4f67-8fc6-ca251fdec1e9',
  google_map_api_key: "AIzaSyB1QYSBwQruBP5Q5UpT9xDaSFn0hJwnNFg",
  version: version,
  line: {
    channel_id: '1657510567',
    liffId: '1657510567-KW8M87gr',
  },
  ket_cus_id: 16910,
  ket_services_path: "https://api.ketshoptest.com/services",
  ketcms_domain: "uat.ketshoptest.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
