import { Action } from '@ngrx/store';


export enum AccountTypes {
  GET_PROFILE = '[Account][API] GET_PROFILE',
  SET_PROFILE = '[Account] SET_PROFILE'
}

export class SET_PROFILE implements Action{
  readonly type = AccountTypes.SET_PROFILE;
  constructor(public playload: any){}
}


export type AccountAction
    =  SET_PROFILE
