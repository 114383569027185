import { ApplicationRef, Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { NzIconService } from 'ng-zorro-antd/icon';
import { NavigationEnd, Router } from '@angular/router';
import { INIT_CART } from './actions';
import { Meta, Title } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { filter, first } from "rxjs/operators";
import { prod_version } from "src/environments/version";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  preload:boolean = true
  isSwUpdating = false;

  constructor(
    private store: Store<any>,
    private iconService: NzIconService,
    protected _router: Router,
    private meta: Meta,
    private title: Title,
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
  ){
    console.log('Start App')
  }

  sleep(m:number){
    return new Promise((s)=>{
      setTimeout(() => {
        s(true)
      },m);
    })
  }

  async ngOnInit(){
    
    if (environment.production) {
      console.log("Is Production");
      const appIsStable$ = this.appRef.isStable.pipe(
        first((isStable) => isStable === true)
      );
      appIsStable$.subscribe(async (isStable) => {
        console.log("Is App stable?", isStable);
        if (isStable) {
          console.log("App is stable");
          this._router.events
            .pipe(
              filter(
                (evt): evt is NavigationEnd => evt instanceof NavigationEnd
              )
            )
            .subscribe((evt) => {
              if (evt) {
                this.checkForUpdate();
              }
            });
          this.checkForUpdate();
        }
      });
    }

    /// Normal
    this.meta.updateTag({ name: 'title', content: 'Yalamarketplace' });
    this.meta.updateTag({ name: 'description', content: 'ตลาดออนไลน์สำหรับชาวยะลา เพื่อให้ชุมชนของเราเข้มแข็ง เศรษฐกิจเดินหน้า ช่วยกันซื้อเพื่อพี่น้องชาวยะลา "หลาดยะลา"' });
    this.meta.updateTag({ name: 'url', content: this._router.url });
    this.meta.updateTag({ name: 'image', content: 'https://yalamarketplace.com/assets/images/yala-og.jpeg' });
          

    /// Facebook
    this.meta.updateTag({ name: 'og:title', content: 'Yalamarketplace' });
    this.meta.updateTag({ name: 'og:description', content: 'ตลาดออนไลน์สำหรับชาวยะลา เพื่อให้ชุมชนของเราเข้มแข็ง เศรษฐกิจเดินหน้า ช่วยกันซื้อเพื่อพี่น้องชาวยะลา "หลาดยะลา"' });
    this.meta.updateTag({ name: 'og:url', content: this._router.url });
    this.meta.updateTag({ name: 'og:image', content: 'https://yalamarketplace.com/assets/images/yala-og.jpeg' });

    /// Twitter
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:title', content: 'Yalamarketplace' });
    this.meta.updateTag({ name: 'twitter:description', content: 'ตลาดออนไลน์สำหรับชาวยะลา เพื่อให้ชุมชนของเราเข้มแข็ง เศรษฐกิจเดินหน้า ช่วยกันซื้อเพื่อพี่น้องชาวยะลา "หลาดยะลา"' });
    this.meta.updateTag({ name: 'twitter:url', content: this._router.url });
    this.meta.updateTag({ name: 'twitter:image', content: 'https://yalamarketplace.com/assets/images/yala-og.jpeg' });

    localStorage.removeItem('access_token')
    this.store.dispatch(new INIT_CART())
    // localStorage.setItem('route_products', 'สินค้า')
    this.store.pipe(select('action', 'preload')).subscribe((preload: boolean)=>{
      this.preload = preload
    });

    try{
      this.iconService.fetchFromIconfont({
        scriptUrl: 'https://at.alicdn.com/t/font_1710337_umg8g15s5ba.js'
      });
    }catch(err){}

    // console.log(this._location.path())
    // console.log(localStorage)

    try{
      // window['$crisp'] = [];
      // window['CRISP_WEBSITE_ID'] = "d71c883f-908b-4b19-85cb-2f5131df3510";
      // if(location.href.search('/system') == -1){
      //   if(location.href.search('/seller') !== -1){
      //     return
      //   }
      //   (function() {
      //     var d:any = document;
      //     var s:any = d.createElement("script");
      //     s.src = "https://client.crisp.chat/l.js";
      //     s.async = 1;
      //     d.getElementsByTagName("head")[0].appendChild(s);
      //   })();
      // }
    }catch(err){}

  }

 checkForUpdate() {
    console.log("Checking for Service Workers update...");
    let localVersion = localStorage.getItem("version");
    if ((!localVersion || localVersion != prod_version) && !this.isSwUpdating) {
      this.isSwUpdating = true;
      this.swUpdate
        .activateUpdate()
        .then(() => {
          localStorage.setItem("version", prod_version);
          console.log("Service Workers were update reaload in 1 sec!");
          setTimeout(() => {
            this.isSwUpdating = false;
            document.location.reload();
          }, 1000);
        })
        .catch(() => {
          this.isSwUpdating = false;
        });
    }
  }


}
