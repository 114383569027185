import { ActionAction, ActionTypes } from '../actions';
interface actionState {
  preload: boolean
  market_menu: boolean
  market_cart: boolean
}


let InitialState: actionState = {
    preload: false,
    market_menu: true,
    market_cart: true
}

export function ActionStore(state = InitialState, action: ActionAction): actionState {
    switch (action.type) {
        case ActionTypes.PRELOAD:
            return {
                ...state,
                preload: state.preload?false:true
            }
        case ActionTypes.MARKET_MENU:
          return {
            ...state,
            market_menu: state.market_menu?false:true
          }
        case ActionTypes.MARKET_CART:
          return {
            ...state,
            market_cart: state.market_cart?false:true
          }
        default:
            return state
    }
}

