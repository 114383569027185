import { AccountTypes, AccountAction } from '../actions';


interface accountState {
    me: any
}


let InitialState: accountState = {
    me: {}
}

export function AccountStore(state = InitialState, action: AccountAction): accountState {
    switch (action.type) {
        case AccountTypes.SET_PROFILE:
            // console.log(action.playload)
            try{
              localStorage.setItem('account_type', action.playload.type)
            }catch(err){}
            return {
                ...state,
                me: action.playload
            }
        default:
            return state
    }
}

